export const localEnv = {
  wavetronix: {
    windowURL: 'documentsmanagement.wtxdev.com',
    runMode: 'dev',
    clientId: 'c1aee92c-843a-4d20-b9ed-d5dcfbb9ee90',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com',
      // gatekeeperURL: 'http://localhost:5001',
      documentsURL: 'https://wtx-mydocuments-rest.wtxdev.com'
      // documentsURL: 'http://localhost:5000'
    },
    basicAuthentication: {}
  },
  // wavetronix: {
  //   windowURL: 'documentsmanagement.wavetronix.com',
  //   runMode: 'prod',
  //   clientId: 'abae75c3-e969-41f6-a853-732af05e6647',
  //   urls: {
  //     gatekeeperURL: 'https://wtx-gatekeeper-rest.wavetronix.com',
  //     // gatekeeperURL: 'http://localhost:5001',
  //     documentsURL: 'https://wtx-mydocuments-rest.wavetronix.com'
  //     // documentsURL: 'http://localhost:5000'
  //   },
  //   basicAuthentication: {}
  // },
  islandradar: {
    windowURL: 'documentsmanagement.islandradardev.com',
    runMode: 'dev',
    clientId: 'c1aee92c-843a-4d20-b9ed-d5dcfbb9ee90',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com/islandradar',
      documentsURL: 'https://wtx-mydocuments-rest.wtxdev.com/islandradar'
      // documentsURL: 'http://localhost:5000/islandradar'
    },
    basicAuthentication: {}
  },
  fathom: {
    windowURL: 'documentsmanagement.fathomradar.com',
    runMode: 'prod',
    clientId: 'abae75c3-e969-41f6-a853-732af05e6647',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wavetronix.com/fathom',
      documentsURL: 'https://wtx-mydocuments-rest.wavetronix.com/fathom'
      // documentsURL: 'http://localhost:5000/fathom'
    },
    basicAuthentication: {}
  }
}
