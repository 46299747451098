import { CircularProgress, DialogContent, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
  CenteredDiv,
  CustomSelect,
  ErrorMessage,
  PrimaryButton,
  SnackbarVariants,
  useWtxLocalization,
  SlideUpDialog,
  WtxColors
} from '@wavetronix/common-components'
import DocumentTypeSelect from '../DocumentTypeSelect'
import { useSnackbar } from 'notistack'
import { useState, useEffect, useMemo } from 'react'
import { useMsal } from '@azure/msal-react'
import { TextField } from '@mui/material'
import DocumentsApi, { FILE_SIZE } from '../../api/DocumentsApi'
import ProductTypeSelector from '../controls/ProductTypeSelector'
import CategoriesApi from '../../api/CategoriesApi'
import FileUploader from '../controls/FileUploader'
import CrmProductsApi from '../../api/CrmProductsApi'
import CrmProductTypeSelector from '../controls/CrmProductTypeSelector'
import { useParams } from 'react-router-dom'

const classes = {
  paper: {
    position: 'absolute',
    width: '50%',
    height: '40%',
    backgroundColor: 'white',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '16px 32px 24px'
  },
  outside: {
    backgroundColor: 'white'
  },
  fileMetadata: {
    width: '80%',
    marginTop: '15px'
  }
}

const BASE_DOC_DEFAULT = {
  description: '',
  products: [],
  crmProducts: [],
  order: 1
}

const NEXUS_DOC_DEFAULT = {
  accessLevel: 'Intermediate',
  documentType: null,
  language: []
}

const MANUFACTURING_DOC_DEFAULT = {
  productId: '',
  workInstruction: ''
}

export default function UploadDocumentModal(props) {
  const { instance, accounts } = useMsal()
  const { unitId } = useParams()
  const [newDocInfo, setNewDocInfo] = useState(BASE_DOC_DEFAULT)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [file, setFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  let localizedStrings = useWtxLocalization()

  const {
    data: categories,
    isLoading: catergoriesLoading,
    error: catergoriesError
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let categories = await CategoriesApi.getCategories(instance, accounts)
      return categories.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
  })

  const { data: crmProducts } = useQuery({
    queryKey: ['crmProducts'],
    queryFn: async () => {
      let categories = await CrmProductsApi.getCrmProducts(instance, accounts)
      return categories.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
    }
  })

  useEffect(() => {
    if (unitId === 'nexus') {
      setNewDocInfo(info => ({ ...info, NEXUS_DOC_DEFAULT }))
    } else if (unitId === 'manufacturing') {
      setNewDocInfo(info => ({ ...info, MANUFACTURING_DOC_DEFAULT }))
    }
  }, [unitId, setNewDocInfo])

  const { documentTypes, productTypes, languageTypes } = useMemo(() => {
    let docTypes = []
    let prodTypes = []
    let langTypes = []
    const updateDocType = value => {
      setNewDocInfo(info => ({ ...info, documentType: value }))
    }

    if (categories) {
      docTypes = categories.filter(c => c.categoryType === 'Document Type').sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
      prodTypes = categories.filter(c => c.categoryType === 'Product').sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
      langTypes = categories.filter(c => c.categoryType === 'Language').sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))

      if (docTypes && docTypes.length > 0) {
        updateDocType(docTypes[0])
      }
    }
    return { documentTypes: docTypes, productTypes: prodTypes, languageTypes: langTypes }
  }, [categories, setNewDocInfo])

  const invalidDocInfo = useMemo(() => {
    let baseCheck = !file || isUploading || file.size >= FILE_SIZE
    let unitCheck = false

    if (newDocInfo) {
      if (unitId === 'nexus' && newDocInfo.products && newDocInfo.language && newDocInfo.accessLevel) {
        unitCheck =
          newDocInfo.products.length <= 0 ||
          newDocInfo.language.length <= 0 ||
          !newDocInfo.documentType ||
          newDocInfo.accessLevel === '' ||
          !newDocInfo.accessLevel
      } else if (unitId === 'manufacturing' && newDocInfo.productId && newDocInfo.workInstruction) {
        unitCheck = newDocInfo.productId === '' || newDocInfo.workInstruction === ''
      } else {
        unitCheck = true
      }
    }
    return baseCheck || unitCheck
  }, [file, isUploading, newDocInfo, unitId])

  function createDocInfo() {
    let docInfo = {
      description: newDocInfo.description,
      products: newDocInfo.products.map(prod => prod.id),
      crmProducts: newDocInfo.crmProducts,
      order: newDocInfo.order,
      fileName: file.name
    }

    if (unitId === 'nexus') {
      docInfo = {
        ...docInfo,
        accessLevel: newDocInfo.accessLevel,
        documentType: newDocInfo.documentType.id,
        language: newDocInfo.language.map(lang => lang.id)
      }
    } else if (unitId === 'manufacturing') {
      docInfo = {
        ...docInfo,
        productId: newDocInfo.productId,
        workInstruction: newDocInfo.workInstruction
      }
    }

    return docInfo
  }

  async function uploadWithFormData() {
    const formData = new FormData()
    formData.append('document', file)
    let infoKey = enqueueSnackbar(`Uploading file info...`, SnackbarVariants.LOADING)
    let docInfo = createDocInfo()
    await DocumentsApi.createInfo(instance, accounts, docInfo, unitId)
      .then(async response => {
        closeSnackbar(infoKey)
        enqueueSnackbar(localizedStrings.snackbar.infoUploaded, SnackbarVariants.SUCCESS)
        let fileKey = enqueueSnackbar(`Uploading file to blob storage...`, SnackbarVariants.LOADING)

        await DocumentsApi.uploadDocument(instance, accounts, 'multipart/form-data', formData, response, unitId)
          .then(response => {
            closeSnackbar(fileKey)
            enqueueSnackbar(localizedStrings.snackbar.docUploaded, SnackbarVariants.SUCCESS)
          })
          .catch(error => {
            closeSnackbar(fileKey)
            enqueueSnackbar(localizedStrings.snackbar.docUploadedFailed, SnackbarVariants.ERROR)
          })
      })
      .catch(error => {
        enqueueSnackbar(localizedStrings.snackbar.infoUploadedFailed, SnackbarVariants.ERROR)
      })
    closeSnackbar(infoKey)
  }

  function onClose() {
    props.refetch()
    props.onClose()
    setNewDocInfo(BASE_DOC_DEFAULT)
    setFile(null)
  }

  if (catergoriesLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  if (catergoriesError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={catergoriesError} />
      </CenteredDiv>
    )
  }

  const unitComponents = {
    documentInfo: {
      nexus: (
        <CenteredDiv>
          <Stack spacing={2} direction='row' sx={{ width: '80%' }}>
            <CustomSelect
              style={{ width: '100%' }}
              id='documentAccessLevelSelect'
              label={localizedStrings.accessLevel}
              required={true}
              disabled={isUploading}
              value={newDocInfo && newDocInfo.accessLevel ? newDocInfo.accessLevel : ''}
              onChange={e => setNewDocInfo(info => ({ ...info, accessLevel: e.target.value }))}
              options={['Basic', 'Intermediate', 'Advanced']}
            />
            <TextField
              id='documentOrderTextField'
              inputProps={{ min: 1 }}
              type='number'
              disabled={isUploading}
              variant='outlined'
              onChange={e => setNewDocInfo(info => ({ ...info, order: e.target.value }))}
              label={localizedStrings.order}
              required
              value={newDocInfo && newDocInfo.order ? newDocInfo.order : ''}
              size='small'
            />
          </Stack>
        </CenteredDiv>
      ),
      manufacturing: (
        <CenteredDiv>
          <Stack spacing={2} direction='row' sx={{ width: '80%' }}>
            <TextField
              id='documentProductIdLevelSelect'
              label={localizedStrings.productId}
              required={true}
              disabled={isUploading}
              value={newDocInfo && newDocInfo.productId ? newDocInfo.productId : ''}
              onChange={e => setNewDocInfo(info => ({ ...info, productId: e.target.value }))}
              size='small'
              sx={{ width: '40%' }}
            />
            <TextField
              id='documentWorkInstructionLevelSelect'
              label={localizedStrings.workInstruction}
              required={true}
              disabled={isUploading}
              value={newDocInfo && newDocInfo.workInstruction ? newDocInfo.workInstruction : ''}
              onChange={e => setNewDocInfo(info => ({ ...info, workInstruction: e.target.value }))}
              size='small'
              sx={{ width: '40%' }}
            />
            <TextField
              id='documentOrderTextField'
              inputProps={{ min: 1 }}
              type='number'
              disabled={isUploading}
              variant='outlined'
              onChange={e => setNewDocInfo(info => ({ ...info, order: e.target.value }))}
              label={localizedStrings.order}
              required
              value={newDocInfo && newDocInfo.order ? newDocInfo.order : ''}
              size='small'
              sx={{ width: 'auto' }}
            />
          </Stack>
        </CenteredDiv>
      )
    },
    documentProducts: (
      <CenteredDiv>
        <ProductTypeSelector
          title={localizedStrings.products}
          selectorStyle={{ display: 'flex', width: '80%', marginTop: 15 }}
          documentProductTypes={newDocInfo && newDocInfo.products ? newDocInfo.products : []}
          productTypes={productTypes}
          onChange={selectedTypes => setNewDocInfo(info => ({ ...info, products: selectedTypes }))}
        />
      </CenteredDiv>
    ),
    documentCRMs: (
      <CenteredDiv>
        <CrmProductTypeSelector
          title={'CRM Products'}
          selectorStyle={{ display: 'flex', width: '80%', marginTop: 15 }}
          documentProductTypes={newDocInfo && newDocInfo.crmProducts ? newDocInfo.crmProducts : []}
          productTypes={crmProducts}
          onChange={selectedTypes => setNewDocInfo(info => ({ ...info, crmProducts: selectedTypes.map(t => t.id) }))}
        />
      </CenteredDiv>
    ),
    documentType: {
      nexus: (
        <CenteredDiv>
          <DocumentTypeSelect
            style={{ width: '80%', marginTop: 15 }}
            required
            disabled={isUploading}
            currentValue={newDocInfo && newDocInfo.documentType ? newDocInfo.documentType : null}
            documentTypes={documentTypes}
            onChange={selectedType => setNewDocInfo(info => ({ ...info, documentType: selectedType }))}
          />
        </CenteredDiv>
      ),
      manufacturing: null
    },
    documentLanguages: {
      nexus: (
        <CenteredDiv>
          <ProductTypeSelector
            title={localizedStrings.languages}
            selectorStyle={{ display: 'flex', width: '80%', marginTop: 15 }}
            documentProductTypes={newDocInfo && newDocInfo.language ? newDocInfo.language : []}
            productTypes={languageTypes}
            onChange={selectedTypes => setNewDocInfo(info => ({ ...info, language: selectedTypes }))}
          />
        </CenteredDiv>
      ),
      manufacturing: null
    },
    documentDescriptionActions: (
      <>
        <CenteredDiv>
          <TextField
            id='documentDescriptionTextField'
            disabled={isUploading}
            label={localizedStrings.description}
            variant='outlined'
            sx={classes.fileMetadata}
            multiline
            minRows={4}
            value={newDocInfo && newDocInfo.description ? newDocInfo.description : ''}
            onChange={e => setNewDocInfo(info => ({ ...info, description: e.target.value }))}
          />
        </CenteredDiv>
        <CenteredDiv>
          <FileUploader
            disabled={isUploading}
            style={classes.fileMetadata}
            handleFile={setFile}
            fileTypes='*'
            prompt={localizedStrings.chooseFile}
          />
        </CenteredDiv>
        <CenteredDiv>
          {file && file.size > FILE_SIZE ? (
            <div style={{ color: WtxColors.INNOVATION_RED }}>Files size is to large for upload.</div>
          ) : null}
        </CenteredDiv>
      </>
    )
  }

  return (
    <SlideUpDialog
      id='uploadDocumentModal'
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>Upload Document</h3>}
      actions={
        <PrimaryButton
          id='uploadDocumentButton'
          style={{ marginLeft: 5 }}
          disabled={invalidDocInfo}
          onClick={async () => {
            setIsUploading(true)
            await uploadWithFormData()
            setIsUploading(false)
            onClose()
          }}
        >
          &nbsp;Upload
        </PrimaryButton>
      }
    >
      <DialogContent>
        <>
          {unitComponents.documentInfo[unitId]}
          {unitComponents.documentProducts}
          {unitComponents.documentCRMs}
          {unitComponents.documentType[unitId]}
          {unitComponents.documentLanguages[unitId]}
          {unitComponents.documentDescriptionActions}
        </>
      </DialogContent>
    </SlideUpDialog>
  )
}
